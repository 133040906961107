import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { Spring, config } from 'react-spring'
import { COLORS } from 'lib/const'

const ProgressBar = ({ color, value, valueVisible }) => (
  <div className={classnames('progress-bar', 'progress-bar--' + color)} style={{ width: (Math.min(value, 100) || 0) + '%' }}>
    {
      valueVisible &&
      <Spring
        from={{ number: 0 }}
        to={{ number: value || 0 }}
        config={{ ...config.stiff, clamp: true }}
      >
        { props => <div className='progress__label'>{Math.round(props.number, 2)}%</div> }
      </Spring>
    }
  </div>
)

ProgressBar.defaultProps = {
  color: 'default'
}

ProgressBar.propTypes = {
  color: PropTypes.oneOf(COLORS),
  value: PropTypes.number
}

const ProgressLine = ({ value }) => (
  <div className='progress__line' style={{ width: (Math.min(value, 100) || 0) + '%' }} />
)

ProgressLine.propTypes = {
  value: PropTypes.number
}

const Progress = ({ children, small, ...rest }) => (
  <div className={classnames('progress', { 'progress--small': small })}>
    {children || <ProgressBar {...rest} />}
  </div>
)

Progress.defaultProps = {
  ...ProgressBar.defaultProps,
  small: false,
  valueVisible: true
}

Progress.propTypes = {
  ...ProgressBar.propTypes,
  value: PropTypes.number,
  valueVisible: PropTypes.bool,
  small: PropTypes.bool
}

export { ProgressBar, ProgressLine }
export default Progress
