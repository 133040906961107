import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import Progress from 'components/Progress'
import { FormattedMessage } from 'react-intl'
import { Characters } from 'lib/const'
import ReportGraphDetails from 'components/ReportGraphDetails'
import ReportGraphPartialProductionDetails from 'components/ReportGraphPartialProductionDetails'
import ReportGraphPartialProductionProgress from 'components/ReportGraphPartialProductionProgress'

const ReportGraph = ({ values, color, character, small, showLabel }) => {
  const [realized, planned, partiallyPlanned] = values
  color = color || {
    [Characters.PRODUCTION]: 'success',
    [Characters.CHANGEOVER]: 'default',
    [Characters.STOPPAGE]: 'danger',
    [Characters.STOPPAGE_PLANNED]: 'danger',
    [Characters.STOPPAGE_UNPLANNED]: 'surprise',
    [Characters.STOPPAGE_PROTOTYPING]: 'prototyping',
    [Characters.SCRAP]: 'info'
  }[character]
  let percentage

  if (planned === 0 && realized > 0) {
    percentage = 100.0
  } else if (planned === 0 && realized === 0) {
    percentage = 0.0
  } else {
    percentage = Math.round(1000 * realized / planned) / 10.0
  }

  return (
    <div className={classnames('report-graph', `report-graph--${color}`, { 'report-graph--small': small })}>
      {
        showLabel &&
        <div className='report-graph__label'>
          <span className='report-graph__character'>
            { character === Characters.PRODUCTION && <FormattedMessage id='components.reportGraph.production' defaultMessage='Production' /> }
            { character === Characters.STOPPAGE && <FormattedMessage id='components.reportGraph.stoppage' defaultMessage='Stoppage' /> }
            { character === Characters.STOPPAGE_PLANNED && <FormattedMessage id='components.reportGraph.stoppagePlanned' defaultMessage='Planned stoppage' /> }
            { character === Characters.STOPPAGE_UNPLANNED && <FormattedMessage id='components.reportGraph.stoppageUnplanned' defaultMessage='Unplanned stoppage' /> }
            { character === Characters.STOPPAGE_PROTOTYPING && <FormattedMessage id='components.reportGraph.stoppagePrototyping' defaultMessage='Prototyping stoppage' /> }
            { character === Characters.SCRAP && <FormattedMessage id='components.reportGraph.scrap' defaultMessage='Scrap' /> }
          </span>
          <span className='report-graph__percentage'>
            {
              isNaN(percentage)
                ? '---'
                : percentage.toFixed(1) + '%'
            }
          </span>
        </div>
      }

      <div className='report-graph__content'>
        {
          partiallyPlanned === undefined
            ? <ReportGraphDetails planned={planned} realized={realized} character={character} />
            : <ReportGraphPartialProductionDetails planned={planned} realized={realized} partiallyPlanned={partiallyPlanned} />
        }

        <div className='report-graph__graph'>
          {
            partiallyPlanned === undefined
              ? <Progress small={small} color={color} value={percentage} valueVisible={false} />
              : <ReportGraphPartialProductionProgress small={small} realized={realized} planned={planned} partiallyPlanned={partiallyPlanned} />
          }
        </div>
      </div>
    </div>
  )
}

ReportGraph.defaultProps = {
  small: Progress.defaultProps.small,
  showLabel: true
}

ReportGraph.propTypes = {
  values: PropTypes.arrayOf(PropTypes.number).isRequired,
  character: Characters.propType,
  small: Progress.propTypes.small,
  showLabel: PropTypes.bool,
  color: PropTypes.string
}

export default ReportGraph
