import React from 'react'
import PropTypes from 'prop-types'
import Progress from 'components/Progress'
import classnames from 'classnames'
import { FormattedMessage } from 'react-intl'
import Unit from 'components/Unit'

const ReportGraphPartialProductionDetails = ({ planned, realized, partiallyPlanned }) => {
  const diff = realized - partiallyPlanned

  return (
    <div className='report-graph__details'>
      <span className='report-graph__realized'>
        <FormattedMessage id='components.reportGraph.partialProduction.realized' defaultMessage='{realized} Realized' values={{ realized }} />
      </span>

      <span className='report-graph__divider'>
        &nbsp;/&nbsp;
      </span>

      <span className='report-graph__planned'>
        <FormattedMessage
          id='components.reportGraph.partialProduction.planned'
          defaultMessage='{planned} Planned'
          values={{ planned: partiallyPlanned }}
        />
      </span>

      <span className='report-graph__paren'>
        &nbsp;(
      </span>

      <span className={classnames('report-graph__diff', diff >= 0 ? 'report-graph__diff--positive' : 'report-graph__diff--negative')}>
        <Unit value={diff >= 0 ? `+${diff}` : diff} unit='piece' />
      </span>

      <span className='report-graph__paren'>
        )&nbsp;
      </span>
    </div>
  )
}

ReportGraphPartialProductionDetails.defaultProps = {
  small: Progress.defaultProps.small
}

ReportGraphPartialProductionDetails.propTypes = {
  planned: PropTypes.number,
  realized: PropTypes.number,
  partiallyPlanned: PropTypes.number
}

export default ReportGraphPartialProductionDetails
