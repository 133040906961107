export const selectToken = state => state.config.token
export const selectTimezone = state => state.config.timezone
export const selectPlant = state => state.plant
export const selectLocationIds = state => state.locations.allIds
export const selectVisibleLocationIds = state => state.locations.allIds.filter(id => state.locations.isVisibleById[id])
export const selectLocation = (state, id) => state.locations.allById[id]
export const selectIsLocationVisible = (state, id) => state.locations.isVisibleById[id]
export const selectLocationReport = (state, id) => state.locations.reportById[id]
export const selectReportIdsByLocation = state => state.reports.combinedIdsByLocation
export const selectReport = (state, id) => state.reports.allByCombinedId[id]
export const selectProject = (state, id) => state.projects.allById[id]
