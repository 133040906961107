import { combineReducers } from 'redux'
import { handleActions } from 'redux-actions'
import Actions from 'reports/production/actions'
import { allIds, allById, customById } from 'lib/reducers'
import navigation from 'containers/Navigation/reducer'

const emptyReport = () => []
const extractReport = ({ production }) => production

const handleReceiveReports = (type) => (state, { payload: reports }) => ({
  ...state,
  reportById: customById(
    reports.filter(report => report.type === type),
    extractReport
  )
})

const config = handleActions(
  {
    [Actions.SET_TOKEN]: (state, { payload: token }) => ({ ...state, token })
  },
  { token: null, locale: null, timezone: null }
)

const plant = handleActions(
  {
    [Actions.START_INIT]: state => ({ ...state, report: emptyReport() }),
    [Actions.RECEIVE_REPORTS]: (state, { payload: reports }) => ({
      ...state,
      report: reports.length > 0 ? reports.filter(s => s.type === 'plant')[0].production : []
    })
  },
  { name: '', report: emptyReport() }
)

const init = handleActions(
  {
    [Actions.START_INIT]: state => ({ ...state, isFinished: false }),
    [Actions.FINISH_INIT]: state => ({ ...state, isFinished: true })
  },
  { isFinished: false }
)

const locations = handleActions(
  {
    [Actions.RECEIVE_LOCATIONS]: (state, { payload: locations }) => ({
      allById: allById(locations),
      allIds: allIds(locations),
      isVisibleById: customById(locations, true),
      reportById: customById(locations, emptyReport())
    }),
    [Actions.RECEIVE_REPORTS]: handleReceiveReports('location'),
    [Actions.SHOW_LOCATION]: (state, { payload: id }) => ({
      ...state,
      isVisibleById: { ...state.isVisibleById, [id]: true }
    }),
    [Actions.HIDE_LOCATION]: (state, { payload: id }) => ({
      ...state,
      isVisibleById: { ...state.isVisibleById, [id]: false }
    })
  },
  { allIds: [], visibleIds: [], allById: {}, reportById: {} }
)

const projects = handleActions(
  {
    [Actions.RECEIVE_PROJECTS]: (state, { payload: projects }) => ({
      allById: allById(projects),
      allIds: allIds(projects)
    })
  },
  { allById: {}, allIds: [] }
)

const combinedReportId = report => `${report.locationId}.${report.id}`

const reports = handleActions(
  {
    [Actions.RECEIVE_LOCATIONS]: (state, { payload: locations }) => ({
      ...state,
      combinedIdsByLocation: customById(locations, [])
    }),
    [Actions.RECEIVE_REPORTS]: (state, { payload: reports }) => ({
      allByCombinedId:
        reports
          .filter(report => report.locationId && report.id)
          .reduce(
            (obj, report) => {
              obj[combinedReportId(report)] = report.production
              return obj
            },
            {}
          ),
      combinedIdsByLocation:
        reports
          .filter(report => report.locationId && report.id)
          .reduce(
            (grouping, report) => {
              const id = combinedReportId(report)
              if (grouping[report.locationId]) {
                grouping[report.locationId].push(id)
              } else {
                grouping[report.locationId] = [id]
              }
              return grouping
            },
            {}
          )

    })
  },
  { allByCombinedId: {}, combinedIdsByLocation: {} }
)

export default combineReducers({
  config,
  plant,
  init,
  navigation,
  locations,
  projects,
  reports
})
