import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Loader from 'components/Loader'
import { selectPlant } from 'reports/production/selectors'
import { reportShape } from 'reports/production/shapes'
import ProductionReportHeader from 'reports/production/ProductionReportHeader'
import ProductionReportTree from 'reports/production/ProductionReportTree'
import ReportGraph from 'components/ReportGraph'
import { Characters } from 'lib/const'

const ProductionReport = ({ isLoaded, plant }) => {
  if (isLoaded) {
    return (
      <React.Fragment>
        <ProductionReportHeader />

        <div className='report__graphs'>
          <ReportGraph values={plant.report} character={Characters.PRODUCTION} />
        </div>

        <ProductionReportTree />
      </React.Fragment>
    )
  } else {
    return <Loader full />
  }
}

ProductionReport.propTypes = {
  isLoaded: PropTypes.bool.isRequired,
  plant: PropTypes.shape({
    name: PropTypes.string,
    report: reportShape
  }).isRequired
}

const mapStateToProps = (state) => ({
  isLoaded: state.init.isFinished,
  plant: selectPlant(state)
})

export default connect(mapStateToProps)(ProductionReport)
