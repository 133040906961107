import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { selectVisibleLocationIds, selectReportIdsByLocation } from 'reports/production/selectors'
import ProductionReportSection from 'reports/production/ProductionReportSection'
import ProductionReportItem from 'reports/production/ProductionReportItem'

const ProductionReportTree = ({ locationIds, idsByLocation }) => {
  return (
    <React.Fragment>
      {locationIds.map(locationId => (
        <ProductionReportSection key={locationId} id={locationId}>
          {idsByLocation[locationId] && idsByLocation[locationId].map(reportId => (
            <ProductionReportItem key={reportId} id={reportId} />
          ))}
        </ProductionReportSection>
      ))}
    </React.Fragment>
  )
}

ProductionReportTree.propTypes = {
  locationIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  idsByLocation: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
  locationIds: selectVisibleLocationIds(state),
  idsByLocation: selectReportIdsByLocation(state)
})

export default connect(mapStateToProps)(ProductionReportTree)
