import React from 'react'
import PropTypes from 'prop-types'
import Progress, { ProgressBar, ProgressLine } from 'components/Progress'

const ReportGraphPartialProductionProgress = ({
  realized,
  planned,
  partiallyPlanned,
  small
}) => {
  return <Progress small={small} >
    {
      realized >= partiallyPlanned &&
      <React.Fragment>
        <ProgressBar color='success' value={(100 * realized) / planned} />
        <ProgressLine value={100 * partiallyPlanned / planned} />
      </React.Fragment>
    }
    {
      realized < partiallyPlanned &&
      <React.Fragment>
        <ProgressBar color='success' value={100 * realized / planned} />
        <ProgressBar color='danger' value={100 * (partiallyPlanned - realized) / planned} />
      </React.Fragment>
    }
  </Progress>
}

ReportGraphPartialProductionProgress.propTypes = {
  realized: PropTypes.number.isRequired,
  planned: PropTypes.number.isRequired,
  partiallyPlanned: PropTypes.number.isRequired,
  small: PropTypes.bool
}

export default ReportGraphPartialProductionProgress
