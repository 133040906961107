import React from 'react'
import PropTypes from 'prop-types'
import Progress from 'components/Progress'
import { Characters } from 'lib/const'
import Unit from 'components/Unit'

const Value = ({ value, character }) => (
  <Unit
    value={value ? value.toFixed(0) : value}
    unit={[Characters.PRODUCTION, Characters.SCRAP].includes(character) ? 'piece' : 'minute'}
  />
)

Value.propTypes = {
  value: PropTypes.number,
  character: Characters.propType
}

const ReportGraphDetails = ({ planned, realized, character }) => {
  return (
    <div className='report-graph__details'>
      <span className='report-graph__realized'><Value value={realized} character={character} /></span>
      <span className='report-graph__divider'>&nbsp;/&nbsp;</span>
      <span className='report-graph__planned'><Value value={planned} character={character} /></span>
    </div>
  )
}

ReportGraphDetails.defaultProps = {
  small: Progress.defaultProps.small
}

ReportGraphDetails.propTypes = {
  planned: PropTypes.number,
  realized: PropTypes.number,
  character: Characters.propType
}

export default ReportGraphDetails
