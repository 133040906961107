import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import {
  selectProject,
  selectReport
} from 'reports/production/selectors'
import { reportShape } from 'reports/production/shapes'
import { Characters } from 'lib/const'
import ReportGraph from 'components/ReportGraph'

const ProductionReportItem = ({ id, project, report, children }) => {
  return (
    <div className='report-item'>
      <div className='report-item__box'>
        <div className='report-item__title'>
          {project.name}
        </div>

        <ReportGraph values={report} character={Characters.PRODUCTION} small showLabel={false} />
        {children}
      </div>
    </div>
  )
}

ProductionReportItem.propTypes = {
  id: PropTypes.string.isRequired,
  project: PropTypes.shape({
    name: PropTypes.string.isRequired
  }),
  report: reportShape,
  children: PropTypes.any
}

const mapStateToProps = (state, { id: reportId }) => {
  const projectId = parseInt(reportId.split('.')[1])

  return ({
    project: selectProject(state, projectId),
    report: selectReport(state, reportId)
  })
}

export default connect(mapStateToProps)(ProductionReportItem)
