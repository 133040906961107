import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import {
  selectLocation,
  selectLocationReport
} from 'reports/production/selectors'
import { reportShape } from 'reports/production/shapes'
import { Characters } from 'lib/const'
import ReportGraph from 'components/ReportGraph'

const ProductionReportSection = ({ id, report, location, children }) => {
  return (
    <div className='report-section'>
      <div className='report-section__data'>
        <h1 className='report-section__title'>
          {location.name}
        </h1>
        <div className='report-section__graphs'>
          <ReportGraph values={report} character={Characters.PRODUCTION} small showLabel={false} />
        </div>
      </div>

      <div className='report-section__locations'>
        {children}
      </div>
    </div>
  )
}

ProductionReportSection.propTypes = {
  id: PropTypes.number.isRequired,
  children: PropTypes.any,
  report: reportShape,
  location: PropTypes.shape({
    name: PropTypes.string.isRequired
  }).isRequired
}

const mapStateToProps = (state, { id }) => ({
  report: selectLocationReport(state, id),
  location: selectLocation(state, id)
})

export default connect(mapStateToProps)(ProductionReportSection)
